// Bootstrap
@import "bootstrap/bootstrap";

// Initialize
@import "variables";
@import "mixins";

// Core CSS
@import "reset";
@import "helpers";
@import "layouts";

// Content Blocks
@import "header";
@import "elements";
@import "footer";
@import "extras";

// Custom
.reverse {
    unicode-bidi:bidi-override;
    direction:rtl;
}
.w-18 {
    width: 18px;
}
.pointer {
    cursor: pointer;
}
